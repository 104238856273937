import { getModule, Module, Mutation, Action, MutationAction, VuexModule } from 'vuex-module-decorators';
import { unitOfTime } from 'moment';
import store from '@/store';
import { User, UserSubmit, UserData } from '@/types';
import * as API from '@/store/api';
import usersModule from '@/store/modules/users';
import dataModule from '@/store/modules/data';
import * as deps from '@/dependencies';
import { AxiosResponse } from 'axios';
import { EventBus, BusEvents } from '@/helpers/eventbus';
import { UserGroupSymbols, ModuleType } from '@/consts';
import moduleManager from '@/store/modules/moduleManager';
import { logProgress, resetProgress } from '@/helpers';

interface Settings {
  minDaysCtb: number;
  timeToSupervision: {
    unit: unitOfTime.Diff;
    time: number;
  };
  daysToMakeSupervision: number;
}

@Module({
  namespaced: true,
  name: 'user',
  store,
  dynamic: true,
})
class UserModule extends VuexModule {
  public user: User = null;

  public pinUser?: Map<number, string> = new Map();
  settings: Settings;
  count = 0;
  refresh = 0;

  public userData: UserData = null;

  get fullName() {
    return this.user.firstname + ' ' + this.user.lastname;
  }
  get wholeUser() {
    return this.user;
  }

  get pin() {
    return this.pinUser[this.user.id];
  }

  @Mutation
  clear() {
    this.user = null;
    this.userData = null;
    this.pinUser = null;
    this.settings = null;
    console.log('User module cleared...');
  }

  @Mutation
  setUser(user: User) {
    console.log('setuser', user);
    this.user = user;
  }

  get isPinSet() {
    this.refresh;
    if (!this.pinUser || !this.pinUser.has(this.user.id)) {
      return true;
    }
    return !!this.pinUser[this.user.id];
  }

  @Mutation
  setPinHash(hash: string) {
    this.pinUser[this.user.id] = hash;
    this.refresh++;
  }

  @Mutation
  reloadUser() {
    this.user = Object.assign(new User(), this.user);
  }

  @Action({ rawError: true })
  async login(userSubmit: UserSubmit): Promise<AxiosResponse> {
    console.log('Vuex user loginUser start...');
    resetProgress();
    const response = await API.loginUser(userSubmit);
    if (response.data.success) {
      logProgress();
      console.log('after API.loginUser then...', response);
      const user = response.data.user;
      const userData = response.data.user_data;
      if (user !== undefined) {
        API.setJWT(user.token);
        console.log('User', user);
        if (this.user && user.id !== this.user.id) {
          deps.clearStore();
        }

        await moduleManager.fetchVersions();

        console.log('Fetching users..');
        await usersModule.fetchUsers();
        logProgress();
        console.log('Fetching rights..');
        await usersModule.loadUserRights();
        logProgress();

        await usersModule.loadUserGroups();
        logProgress();

        const newUser = usersModule.userById(user.id);
        newUser.load();
        this.setUser(newUser);
        this.fetchUserData();

        //this.fetchUserData(); //why dont we just fetch user, and user_data in the first response?

        logProgress();

        //console.log('this user=', user);
        if (user.module === ModuleType.CLIENT) {
          dataModule.clear();
        }

        await deps.loadFromDB();
        console.log('loadFromDB');
        await deps.loadStore();

        await this.fetchSettings();
        await dataModule.addSupervisions();
        await dataModule.fetchMessages();

        EventBus.$emit(BusEvents.LOGIN_PROGRESS, 100);
      }
    }
    return response;
  }

  @Action
  async reset(email: string) {
    console.log('UserModule::Reset');
    return await API.reset(email);
  }

  @Action
  async resetDev(email: string) {
    console.log('UserModule::ResetDEV');
    return await API.resetDev(email);
  }

  get getSettings() {
    return this.settings;
  }

  @Mutation
  setSettings(settings: any) {
    this.settings = settings;
    console.log('user settings', this.settings);
  }

  @Action({ rawError: true })
  async fetchSettings() {
    console.log('user', this.user);
    const params = {
      user: this.user.id,
      groups: this.user.group_ids,
    };
    const response = await API.fetchSettings(params);
    this.setSettings(response.data.settings);
  }

  @Mutation
  @Action
  async logout() {
    API.clearJWT();
    return {
      user: {} as User,
    };
  }

  get hasRightBySymbol() {
    return (rightSymbol: string): boolean => {
      const right = usersModule.getRightBySymbol(rightSymbol);
      console.log('Right:', right);
      let found = false;
      if (right) {
        this.user.right_ids.forEach(r => {
          if (!found && right.id === r) {
            found = true;
          }
        });
      }
      return found;
    };
  }
  get hasRightBySymbolInAllRights() {
    return (rightSymbol: string): boolean => {
      const right = usersModule.getRightBySymbol(rightSymbol);
      console.log('ALLUserRights', right, usersModule.getAllUserRightsList(this.user));
      for (const r of usersModule.getAllUserRightsList(this.user)) {
        if (r.id === right.id) {
          return true;
        }
      }
      return false;
    };
  }

  get userHasRightBySymbolInAllRights() {
    return (userToCheck: User, rightSymbol: string): boolean => {
      const right = usersModule.getRightBySymbol(rightSymbol);
      for (const r of usersModule.getAllUserRightsList(userToCheck)) {
        if (r.id === right.id) {
          return true;
        }
      }
      return false;
    };
  }

  get inGroupBySymbol() {
    return (groupSymbol: UserGroupSymbols): boolean => {
      const group = usersModule.getGroupBySymbol(groupSymbol);
      for (const g of this.user.group_ids) {
        if (group.id === g) {
          return true;
        }
      }
      return false;
    };
  }

  get getUserData() {
    return this.userData;
  }
  @Mutation
  setUserData(userData: UserData) {
    this.userData = userData;
  }
  @Action
  async fetchUserData(): Promise<AxiosResponse> {
    const response = await API.getUserData(this.user.id);
    if (response.data.success === true) {
      const userData = response.data.user_data;
      userData.client_id = userData.client;
      userData.client = null;
      this.user.module = response.data.user_data.module;
      this.setUserData(response.data.user_data);
    }
    return response;
  }
}

export default getModule(UserModule);
